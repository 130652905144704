import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { ASCPageImagesQuery } from '../shared/interfaces';

const ASCPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ASCPageImagesQuery>(graphql`
        query {
            ascBackgroundImage: file(name: {eq: "ascBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            ascSummaryImage: file(name: {eq: "ascSummary"}, extension: {eq: "webp"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | ASC"/>
            <GeneralJumbotron
                title="Ambulatory Surgery Center"
                backgroundImageURL={queryResult?.ascBackgroundImage.publicURL}
                backgroundText="ASC"
            />
            <SummaryTemplate image={queryResult?.ascSummaryImage.publicURL}>
                <p>
                    {`
                        Outpatient care is the new norm in healthcare. Industry leaders now know that ASCs play an important role in population management, and they recognize ASCs as partners in implementing surgical solutions with strong track records of quality care and positive patient outcomes. We are very proud to have contracted over 50 ambulatory surgical centers across California for you.
                    `}
                </p>
                <p>
                    {`
                        When you become a member of PCH, it means more options for your clients—more locations to choose from, quicker appointment times, seamless coordination, and quality of care that you can trust. Our software connects outpatient sites through electronic medical records to help providers coordinate services, keeping patients healthy and out of the hospital.
                    `}
                </p>
                <p>{`We also offer post-rehab solutions!`}</p>
                <p>
                    {`To learn more, call us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default ASCPage;
